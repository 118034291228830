<template>
  <div>
    <donut-page-title :title="$i18n.t('shoutouts.feature')" />
    <donut-tabs
      class="m-vx4"
      :tabs="tabs"
      :initial-tab="initialTab"
      :can-select-tabs="false"
    />
  </div>
</template>

<script setup>
  import { computed, inject } from 'vue';

  const $i18n = inject('$i18n');

  const props = defineProps({
    initialTabValue: { type: String, default: 'basics' },
  });

  const tabs = [
    {
      title: 'Basics',
      value: 'basics',
      link: '/shoutouts/settings',
    },
    {
      title: 'Permissions',
      value: 'permissions',
      link: '/shoutouts/settings/permissions',
    },
    {
      title: _.capitalize($i18n.t('shoutouts.reward', { count: 2 })),
      value: 'rewards',
      link: '/shoutouts/settings/rewards',
    },
  ];

  const initialTab = computed(() => tabs.find(tab => tab.value === props.initialTabValue));
</script>
