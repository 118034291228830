import * as Vue from 'vue';

import * as Sentry from '@sentry/vue';

import DonutChart from 'vue-css-donut-chart';
import VTooltip from 'floating-vue';

import { I18n } from 'i18n-js';

import { store } from '../store/index';

import 'floating-vue/dist/style.css';

import translations from '../locales/en.json';

// These are feature flags for Vue3 to remove/add some enforcement of various rules. The documentation for these online is pretty good if you are curious.
Vue.configureCompat({
  RENDER_FUNCTION: false, // This are required for getting 'floating-vue's VTooltip directive to work with the Vue3 migration build (@vue/compat)
  WATCH_ARRAY: false, // This are required for getting 'floating-vue's VTooltip directive to work with the Vue3 migration build (@vue/compat)
  COMPONENT_V_MODEL: false, // This is because 'modelValue' is correct syntax for Vue3, but the @vue/compat build doesn't like it
});


const plugins = require.context('../plugins', true, /\.\/[^/]+\.(js|coffee)$/);
const directives = require.context('../directives', true, /\.\/[^/]+\.(js|coffee)$/);
const rawComponents = require.context('../components', true, /(\.coffee|\.vue|\.js)$/);
const componentLookup = {};
rawComponents.keys().forEach((key) => {
  const component = rawComponents(key).default;
  const name = key.split('.')[1].split('/').slice(1).join('-').replace(/_/g, '-');
  componentLookup[name] = component;
});


// Initialize

$(() => {
  const vueElements = $('#vue, .vue');
  const vueApps = [];
  if (vueElements.length > 0) {
    store.commit('updateState', Donut.Args);

    vueElements.each((vueElIdx, element) => {
      const component = componentLookup[element.id];
      if (component === undefined) {
        const sentryMessage = `VueComponentMissingFromComponentLookup: ${element.tagName}`;
        console.log(sentryMessage);

        if (window.SENTRY_FRONTEND_DSN) {
          window.Sentry.captureMessage(sentryMessage);
        }
        return;
      }

      // Set initial vue props to the value of `v-bind` or an empty array
      const props = JSON.parse(element.getAttribute('v-bind')) || {};
      // Add any `:` prefixed attributes on the root vue element to props
      if (element.hasAttributes()) {
        for (const a of element.attributes) {
          if (a.name.charAt(0) === ':') {
            props[a.name.slice(1)] = JSON.parse(a.value);
          }
        }
      }

      const app = Vue.createApp(componentLookup[element.id], props);
      app.use(VTooltip, {
        themes: {
          tooltip: {
            delay: {
              show: 0,
              hide: 0,
            },
          },
        },
      });
      app.use(DonutChart);
      app.use(store);

      const i18n = new I18n(translations);
      i18n.missingBehavior = 'error';
      app.provide('$i18n', i18n);

      window.SENTRY_FRONTEND_DSN = process.env.SENTRY_FRONTEND_DSN;
      if (window.SENTRY_FRONTEND_DSN) {
        Sentry.init({
          app,
          environment: process.env.NODE_ENV,
          dsn: window.SENTRY_FRONTEND_DSN,
          integrations: [],
          tracesSampleRate: 0.1,

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', /^https:\/\/app\.donut\.ai/],
        });
        window.Sentry = Sentry;
      }

      plugins.keys().forEach((key) => {
        const plugin = plugins(key).default;
        app.use(plugin);
      });
      directives.keys().forEach((key) => {
        const directive = directives(key).default;
        const name = key.split('.')[1].split('/').slice(1).join('-').replace(/_/g, '-');
        app.directive(name, directive);
      });
      Object.keys(componentLookup).forEach((name) => {
        const componentForName = componentLookup[name];
        app.component(name, componentForName);
      });

      // Sentry should only be initialized the once, but before the apps have been mounted.
      // Thus, we do it after this loop once the apps are ready to be mounted, initializing
      // it with all of them as an array, and _then_ mounting them.
      vueApps.push({ app, element });
    });

    // Initialize Sentry
    window.SENTRY_FRONTEND_DSN = process.env.SENTRY_FRONTEND_DSN;
    if (window.SENTRY_FRONTEND_DSN) {
      Sentry.init({
        app: vueApps.map(a => a.app),
        environment: process.env.NODE_ENV,
        dsn: window.SENTRY_FRONTEND_DSN,
        integrations: [],
        tracesSampleRate: 0.1,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/app\.donut\.ai/],
      });
      const { currentUser, currentTeam } = Donut.Args;
      // If we have access to these Donut.Args values, setUser here
      if (currentUser && currentTeam) {
        Sentry.setUser({
          email: currentUser.email,
          id: currentUser.id,
          uid: currentUser.uid,
          teamId: currentTeam.id,
          teamUid: currentTeam.uid,
        });
      }
      window.Sentry = Sentry;
    }

    vueApps.forEach(({ app, element }) => {
      app.mount(`#${element.id}`);
    });
  }
});
