<template>
  <div class="container">
    <shoutouts-programs-tabs initial-tab-value="basics" />
    <donut-form-item
      label="Channel(s)"
      :description="channelsDesc"
      :left-align="true"
    >
      <div>
        <donut-selector-prefabs-channel
          v-model="channels"
          :is-multi="true"
        />
        <div
          class="t link purple c-p m-t"
          @click="openNewChannelModal"
        >
          Create a new Slack channel
        </div>
      </div>
    </donut-form-item>
  </div>
</template>

<script setup>
  import { computed, inject } from 'vue';
  import { useStore } from 'vuex';

  const { state, commit } = useStore();

  const props = defineProps({
    program: { type: Object, required: true },
  });

  const $i18n = inject('$i18n');

  // Channels
  const channelsDesc = computed(() => `Choose one or more Slack channels where public ${$i18n.t('shoutouts.shoutout', { count: 2 })} will be posted and tracked. You can use an existing one or create a new one just for ${$i18n.t('shoutouts.shoutout', { count: 2 })}.`);
  const channels = computed({
    get() { return state.shoutoutsProgram.channels; },
    set(value) { commit('update', { module: 'shoutoutsProgram', key: 'channels', value }); },
  });
  const openNewChannelModal = () => {
    commit('updateModal', {
      visible: true,
      subcomponent: 'shoutouts-programs-modals-new-channel',
    });
  };
</script>
