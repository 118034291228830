// This composable is used to format the remote path to query based on user input in a dynamic selector and
// to then format the results into options for the selector
import { computed } from 'vue';
import { useStore } from 'vuex';
import useSearchCaching from 'composables/search-caching';

const useDynamicSelector = ({ basePath, searchQuery }) => {
  const { state, commit, dispatch } = useStore();

  const encodedQuery = computed(() => encodeURIComponent(searchQuery.value?.trim()));
  const cache = computed(() => state.selector.searchCache);
  const { fullPath } = useSearchCaching({
    query: encodedQuery,
    basePath,
    externalCaching: {
      cache,
      onBegin: () => {
        commit('update', { module: 'selector', key: 'searching', value: true });
      },
      onSuccess: ({ data, path }) => {
        commit('selector/cacheSearchResult', { data, path });
        commit('update', { module: 'selector', key: 'searching', value: false });
      },
      onFailure: () => {
        dispatch('setErrorToast', 'Could not perform search');
        commit('update', { module: 'selector', key: 'searching', value: false });
      },
    },
  });
  const options = computed(() => state.selector.searchCache[fullPath.value] || []);

  return { options };
};

export default useDynamicSelector;
