<template>
  <div class="t">
    <span>Send reminders every </span>
    <span class="bold">{{ props.periodLabel }}</span>
    <span>, up to </span>
    <span class="bold">{{ props.limitLabel }}</span>
    <span>{{ props.explanationSuffix }}. </span>
    <span
      class="bold link c-p"
      @click.prevent="toggleReminderSettingsOpen"
    >
      {{ modelValue ? 'Collapse' : 'Customize' }}
    </span>
  </div>
</template>

<script setup>
  const props = defineProps({
    modelValue: { type: Boolean, required: true },
    periodLabel: { type: String, required: true },
    limitLabel: { type: String, required: true },
    explanationSuffix: { type: String, default: null },
  });
  const emit = defineEmits(['update:modelValue']);

  const toggleReminderSettingsOpen = () => {
    emit('update:modelValue', !props.modelValue);
  };
</script>
