<template>
  <div class="f-ast f-v">
    <donut-modal-sections-title
      title="Name your Donut channel"
      title-svg="nucleo/slack"
      :title-image-version="1"
    />
    <div class="t gray">{{ description }}</div>
    <donut-form-input
      v-model="channelNameValid"
      class="pos-rel m-tx1"
      :for="channelName"
      name="channel_name"
      :validators="validators"
    >
      <div :class="`pos-abs left-x1 top-10 t ${channelNameValid ? '' : 'dark-red'}`">#</div>
      <input
        v-model="channelName"
        type="text"
        :placeholder="$i18n.t('shoutouts.feature').toLowerCase()"
      >
    </donut-form-input>
    <donut-modal-sections-actions
      :is-save-disabled="!channelNameConfirmedValid"
      save-mutation="shoutoutsProgram/addNewChannel"
      :save-args="channelName"
    />
  </div>
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import useValidators from 'composables/validators';
  import useSearchCaching from 'composables/search-caching';

  const $i18n = inject('$i18n');

  const description = computed(() => `Create a new Slack channel in which Donut will detect and reward public ${$i18n.t('shoutouts.shoutout', { count: 2 })}. Doing this will not trigger any messages to anyone besides you.`);

  // Channel Name Input
  const channelName = ref('');
  const { cacheValueAtFullPath } = useSearchCaching({
    query: channelName,
    basePath: '/channels/search_match',
  });

  // Validation
  const { presenceValidator, slackChannelNameRestrictionValidator, availabilityValidator } = useValidators();
  const channelNameValid = ref(false);
  // availabilityValidator happens on a slight delay, so make sure the cacheValueAtFullPath is complete before declaring the name officially valid
  const channelNameConfirmedValid = computed(() => channelNameValid.value && cacheValueAtFullPath.value?.status === 'complete');

  const validators = computed(() => [
      presenceValidator('A channel name'),
      slackChannelNameRestrictionValidator(),
      availabilityValidator(cacheValueAtFullPath.value),
    ]);
</script>
