<template>
  <div class="container">
    <shoutouts-programs-tabs initial-tab-value="rewards" />
    <div class="t">
      [{{ title }} Coming Soon]
    </div>
  </div>
</template>

<script setup>
  import { computed, inject } from 'vue';

  const $i18n = inject('$i18n');

  const title = computed(() => _.capitalize($i18n.t('shoutouts.reward', { count: 2 })));
</script>
