// This composable is for providing generic "validator" functions to be passed into donut-form-input components.
// It is, in essence, an exact replica of the `validators` plugin. That file applies a mixin which is incompatible
// with Vue 3's Composition API, and as such I've just recreated them as a composable. Feel free to pull in other ones
// from that file, or create new ones of your own, as you see fit.
const useValidators = () => {
  // This is the most generic validator and simply makes sure there is something in the field it is validating
  const presenceValidator = (label = 'This', count = 1) => (value) => {
      if (value == null || (typeof (value) === 'string' && value.trim() === '')) return `${label} is required`;
      if (value.length != null) {
        if (value.length < count) {
          if (count <= 1) return `${label} is required`;
          return `You must select at least ${count} ${label}.`;
        }
      }
    };

  // This validator accepts a `forComparison` array to make sure the value has not been repeated
  const uniquenessValidator = (label = 'This') => (value, forComparison) => {
      let err = null;
      forComparison.forEach((comparableValue) => {
        if (comparableValue.name.toLowerCase().trim() === value.toLowerCase().trim()) {
          err = `${label} must be unique`;
        }
      });
      return err;
    };

  // This validator accepts a `cacheValueAtFullPath`, which is an object with 'status' and 'result' keys, and validates
  // that this object does not have a complete status with an already existing result.
  // NOTE: This validator should be used in conjunction with the `cacheValueAtFullPath` return value of the useSearchCaching composable
  // at `search-caching.js`, or else with a custom search cache result that is formatted with the same keys.
  const availabilityValidator = (cacheValue, label = 'This') => () => {
      const resultsPending = !cacheValue || cacheValue.status === 'pending';
      const confirmedAvailable = cacheValue?.status === 'complete' && !cacheValue?.result;
      const confirmedError = cacheValue?.status === 'error';
      if (confirmedError) return 'There was an error with this search...';
      if (!(resultsPending || confirmedAvailable)) {
        return `${label} already exists`;
      }
    };

  // This validator simply checks a string against the standard name validations that Slack uses for channel names
  const slackChannelNameRestrictionValidator = () => (value) => {
      const regex = /^[a-z0-9-_]+$/;
      if (value.length > 80) {
        return 'Channel names can\'t be longer than 80 characters.';
      } if (value.toLowerCase() !== value) {
        return 'Channel names can\'t contain uppercase letters.';
      } if (!regex.test(value)) {
        return 'Channel names can\'t contain spaces, periods, or most punctuation.';
      }
    };

  return {
    presenceValidator,
    uniquenessValidator,
    availabilityValidator,
    slackChannelNameRestrictionValidator,
  };
};

export default useValidators;
