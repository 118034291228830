<template>
  <div class="container">
    <donut-page-title :title="$i18n.t('shoutouts.feature')" />
    <div class="f-sb m-tx4">
      <div class="t w-70p">
        Donut helps your team recognize great work and celebrate wins with {{ $i18n.t('shoutouts.feature') }}: personalized shoutouts that spread positivity and fit your team's culture, all within Slack.
      </div>
      <a
        class="button"
        :href="scopesUpdateRequired ? null : '/shoutouts/settings'"
        @click="onGetStartedClick"
      >Get Started</a>
    </div>
    <div class="f-sb m-tx4">
      <div
        v-for="(item, idx) in items"
        :key="`item-${idx}`"
        class="f-v f-ac w-max-20p"
      >
        <cdn-img
          :path="`graphics/shoutouts/${item.name}`"
          class="w-max-100p"
        />
        <div class="f-v bg-dark-purple w-x5 h-x5 round ta-c f-jc m-tx1">
          <div class="t-xxl bold white">{{ idx + 1 }}</div>
        </div>
        <div class="t ta-c m-tx1">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, inject } from 'vue';
  import { useStore } from 'vuex';

  const { getters, commit, dispatch } = useStore();

  const $i18n = inject('$i18n');

  const props = defineProps({
    reauthData: { type: Object, required: true },
  });

  const items = [
    { name: 'landing-emoji', text: `Customize your ${$i18n.t('shoutouts.point', { count: 1 })} system` },
    { name: 'landing-channels', text: `Select one or more channels for public ${$i18n.t('shoutouts.shoutout', { count: 2 })}` },
    { name: 'landing-shoutout', text: `Give ${$i18n.t('shoutouts.shoutout', { count: 2 })} publicly or privately` },
    { name: 'landing-rewards', text: `Redeem ${$i18n.t('shoutouts.point', { count: 2 })} for ${$i18n.t('shoutouts.reward', { count: 2 })} — customized for your company (and budget)` },
  ];

  // Slack Scopes
  const scopesUpdateRequired = computed(() => getters['reauthing/slackScopeMissing']('channels_manage'));
  const onGetStartedClick = () => {
    // This will be a valid href click if scopesUpdateRequired is false
    if (scopesUpdateRequired.value) {
      dispatch('reauthing/openPermissionsModal', {
        missingScope: 'channels_manage',
        source: 'Shoutouts landing page',
        description: 'Donut needs to be able to join and create channels in your Slack workspace. To enable this, please accept the permissions in the following screen.',
      });
    }
  };

  // Lifecycle Hooks
  onMounted(() => {
    commit('update', { module: 'reauthing', key: 'slackAuth', value: props.reauthData });
  });
</script>
