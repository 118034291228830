// This composable is a lightweight search cacher. Provided a user inputted query string and a path to search on, it will make the search
// after a small interval of paused user input and cache the results. Components can implement this using the default caching system provided
// (which would be local to the component it's used in) or it can accept an `externalCaching` object with a reference to a separate external cache
// as well callbacks for search results. See composables/selector/dynamic for an example of externalCaching.
import { ref, computed, watch } from 'vue';
import Network from 'lib/api/network';

const useSearchCaching = ({ query, basePath, externalCaching }) => {
  const localCache = ref({});
  const searchTimeout = ref(null);
  const trimmedQuery = computed(() => query.value?.trim());
  const getFullPath = () => {
    // Putting this in a method instead of a computed. If the path is used to perform a search,
    // and the query is changed before the results come in, a computed prop would cache the results
    // at the path of the query as it currently stands, which is incorrect.
    const separator = basePath.includes('?') ? '&' : '?';
    return `${basePath}${separator}query=${trimmedQuery.value}`;
  };
  const fullPath = computed(() => getFullPath());
  const getCacheValueAtPath = (path) => {
    const cache = externalCaching?.cache.value || localCache.value;
    return cache[path];
  };
  const cacheValueAtFullPath = computed(() => getCacheValueAtPath(fullPath.value));

  // Search Callbacks
  const onBegin = ({ path }) => {
    if (externalCaching) {
      externalCaching.onBegin?.({ path });
    } else {
      localCache.value[path] = { status: 'pending', result: null };
    }
  };
  const onSuccess = ({ data, path }) => {
    if (externalCaching) {
      externalCaching.onSuccess?.({ data, path });
    } else {
      localCache.value[path] = { status: 'complete', result: data };
    }
  };
  const onFailure = ({ error, path }) => {
    if (externalCaching) {
      externalCaching.onFailure?.({ error, path });
    } else {
      localCache.value[path] = { status: 'error', result: null };
    }
  };

  // Search Functionality
  const performSearch = (path) => new Promise(() => {
    Network.get(path, {
      success: (data) => { onSuccess({ data, path }); },
      error: (error) => { onFailure({ error, path }); },
    });
  });
  const initiateSearch = () => {
    if (searchTimeout.value) clearTimeout(searchTimeout.value);
    searchTimeout.value = setTimeout(() => {
      const path = getFullPath();
      if (trimmedQuery.value && !getCacheValueAtPath(path)) {
        onBegin({ path });
        performSearch(path);
      }
    }, 350);
  };

  // Lifecycle Hooks
  watch(query, () => {
    initiateSearch();
  });

  return { fullPath, cacheValueAtFullPath };
};

export default useSearchCaching;
